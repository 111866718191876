@retina: ~"only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)";

body {
  margin: 0;
  overflow: hidden;
  font-family: Roboto, 'Helvetica-Neue', 'Segoe UI', Tahoma, Verdana, sans-serif;
  font-weight: 400;
}

h3 {
  margin: 10px 0;
}

p {
  font-size: 14px;
  color: #888;
}

input:focus {
  outline: 2px solid #32C5FF;
}

input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
}

input[type="submit"], button {
  display: inline-block;
  border: 1px solid #ccc;
  background-color: #FAFAFA;
  font-size: 13px;
  font-weight: bold;
  color: #555;
  padding: 9px 18px;

  &:hover {
    cursor: pointer;
    color: #333;
    border-color: #ddd;
    background-color: #eee;
  }

  &.blue {
    border-color: #0E5FA2;
    background-color: #1986E1;
    color: white;

    &:hover {
      border-color: #0b4f86;
      background-color: #1471bd;
    }
  }

  &.dark-blue {
    border-color: #172F46;
    background-color: #26496A;
    color: white;

    &:hover {
      border-color: #102030;
      background-color: rgb(33, 63, 92);
    }
  }

  &.icon {
    padding-right: 32px;
    background-position: right 10px center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
  }

  &.icon-only {
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: top;
    height: 36px;
  }

  &.icon-next {
    background-size: 9px 10px;
    background-image: url('/images/icon_next.png');

    @media @retina {
      background-image: url('/images/icon_next@2x.png');
    }
  }
  &.icon-previous {
    background-size: 9px 10px;
    padding-left: 32px;
    padding-right: 18px;
    background-position: left 10px center;
    background-image: url('/images/icon_previous.png');

    @media @retina {
      background-image: url('/images/icon_previous@2x.png');
    }
  }
  &.icon-next-white {
    background-size: 9px 10px;
    background-image: url('/images/icon_next_white.png');

    @media @retina {
      background-image: url('/images/icon_next_white@2x.png');
    }
  }
  &.icon-next-copy {
    background-size: 20px 22px;
    background-image: url('/images/icon_next_copy.png');

    @media @retina {
      background-image: url('/images/icon_next_copy@2x.png');
    }
  }
  &.icon-previous-copy {
    background-size: 20px 22px;
    background-image: url('/images/icon_previous_copy.png');

    @media @retina {
      background-image: url('/images/icon_previous_copy@2x.png');
    }
  }
  &.icon-plus-white {
    padding-right: 36px;
    background-position: right 16px center;
    background-size: 10px 10px;
    background-image: url('/images/icon_plus_white.png');

    @media @retina {
      background-image: url('/images/icon_plus_white@2x.png');
    }
  }
}

#load-server, #load-class {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  .content-box {
    background: #fff;
    border: 1px solid #ccc;
    width: 550px;
    padding: 32px;

    input[type="text"] {
      width: 100%;
      box-sizing: border-box;
    }

    label {
      font-weight: bold;
    }

    input[type="submit"] {
      float: right;
    }
  }
}

#main {
  // display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;

  .header {
    height: 54px;
    background: #1986E1;
    border-bottom: 2px solid #1572be;
    overflow: hidden;

    h1 {
      font-weight: bold;
      font-size: 21px;
      color: #fff;
      line-height: 28px;
      margin: 14px;
      float: left;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      color: #26496A;
      float: left;
      margin: 2px 0 0;
      line-height: 54px;
    }

    button {
      float: right;
      margin: 9px 12px;
    }
  }
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
  border-top: 1px solid #ccc;
}

.gutter.gutter-vertical {
  cursor: row-resize;
}

#panels {
  position: relative;
  flex: 1 1;
  width: 100%;

  .panel {
    flex: 1 1;
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;

    &:first-of-type {
      .subheader {
        padding-top: 4px;
        height: 45px;
      }
    }

    .subheader {
      background: #EEEEEE;
      border-bottom: 1px solid #ccc;
      height: 42px;
      box-sizing: border-box;
      padding-top: 1px;
      padding-bottom: 4px;

      .class-name {
        color: #999999;
        font-size: 14px;
        font-weight: bold;
        line-height: 38px;
        margin-left: 12px;
      }

      .actions {
        float: right;
        margin-right: 12px;
      }

      button {
        margin: 0px;
        outline: 0;

        &:hover {
          border-color: #aaa;
        }

        &.stick-left {
          margin-right: -5px;
        }

        &.stick-right {
          margin-left: -5px;
        }
      }

      .close {
        background: none;
        border: 0;
        margin-top: 10px;
        vertical-align: top;
        background-position: center center;
        background-size: 14px 13px;
        background-repeat: no-repeat;
        background-image: url('/images/icon_cross.png');

        @media @retina {
          background-image: url('/images/icon_cross@2x.png');
        }
      }

    }

    .editors {
      flex: 1 1;
      position: relative;
      width: 100%;
      box-sizing: border-box;

      .editor {
        margin: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        font-family: "Roboto Mono";
      }

      .proto-editor {
        left: 50%;
        right: 0;
      }
    }

    .tooltip {
      display: none;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      background: #FFF6DC;
      border: 1px solid #E8CD7F;
      background-position: right 16px center;
      padding: 16px 46px 16px 16px;
      background-size: 16px 19px;
      background-repeat: no-repeat;
      background-image: url('/images/icon_clipboard.png');
      position: absolute;
      top: 66px;
      right: 16px;
      z-index: 1000;

      @media @retina {
        background-image: url('/images/icon_clipboard@2x.png');
      }
    }
  }
}

.easy-autocomplete.eac-square ul {
  position: absolute;
  list-style: none;
  padding-left: 0px;
  margin-top: 2px;
  background-color: white;

  li, .eac-category {
    border-color: #7f8c8d;
    margin-top: -1px;
    border: 1px solid #ccc;
    font-family: inherit;
    font-size: 14px;
    font-weight: 300;
    padding: 6px 12px;
    line-height: 20px;

    * {
      font-weight: 300;
    }

    &.selected {
      background-color: #f1f1f1;
      font-weight: 300;
    }

    b {
      font-weight: 600;
    }

  }

  li:hover {
    cursor: pointer;
  }
}
